import axios, { AxiosError } from 'axios';
import { jwtCookie, loginCookie } from '../auth';

import {
  ISiteSettingParams,
  IUserFormParams,
  IUserLoginParams,
  IUserPasswordFormParams,
  TPaginationParams,
  TSearchFormParams
} from '../interfaces/api';
import { errorHandler } from './helper';

const superagent = axios.create({
  baseURL: `/proxy`,
  timeout: 600000
});

/**
 * Used for Token Verification.
 * Used for signature insertion
 * Every request sent to app api will be injected with the jwt {accessToken, refreshToken}
 * Might be subjected to changes if API gateway is used as middleman
 */
superagent.interceptors.request.use(
  (config) => {
    const reqConfig = { ...config };

    const token = jwtCookie.get();

    if (token) reqConfig!.headers!.Authorization = `Bearer ${token}`;

    return reqConfig;
  },
  (error: AxiosError) => Promise.reject(error)
);

/**
 * Used for Login Token Verification in conjunction with OTP verification process.
 * Every request sent to app api will be injected with the jwt {loginToken}
 * Might be subjected to changes if API gateway is used as middleman
 */
superagent.interceptors.request.use(
  (config) => {
    const reqConfig = { ...config };

    const token = loginCookie.get();
    if (token) reqConfig!.headers!.Authorization = `Bearer ${token}`;

    return reqConfig;
  },
  (error: AxiosError) => Promise.reject(error)
);

/**
 * Intercept backend api response and check for error.
 * (response) => Will also check for AccessToken and renew the token accordingly
 * (error: AxiosError) => Will force a redirect if error occurs
 */
superagent.interceptors.response.use(
  (response) => {
    if (!!response.headers['accesstoken']) {
      const tokenHash = JSON.parse(jwtCookie.get() || '');
      jwtCookie.set(
        JSON.stringify({
          ...tokenHash,
          accessToken: response.headers['accesstoken']
        })
      );
    }

    return response;
  },
  (error: AxiosError) => {
    // eslint-disable-next-line no-console
    errorHandler(error);
    return Promise.reject(error);
  }
);

const Auth = {
  logout: () => superagent.delete('/api/auth/jwt/sys-logout'),
  login: ({ email, password }: IUserLoginParams) =>
    superagent.post('/api/auth/sys-login', {
      user: {
        email,
        password
      }
    }),
  verifyToken: () => superagent.post('/api/auth/jwt/verify-token'),
  // Check Whether User setup OTP
  checkOtpSetup: (uid: string) =>
    superagent.post('/api/auth/otp/check-otp-setup', {
      user: {
        uid
      }
    }),
  getQRcodeWithAccessToken: () =>
    superagent.post('/api/auth/otp/generate-qr-code-secure'),
  verifyOtpCodeWithAccessToken: (otpCode: number) =>
    superagent.post('/api/auth/otp/verify-otp-code-secure', {
      otpCode
    }),
  verifyOtpCode: (otpCode: number, uid: string) =>
    superagent.post('/api/auth/otp/verify-otp-code', {
      otpCode,
      uid
    }),
  removeOtpApp: (otpCode: number) =>
    superagent.post('/api/auth/otp/remove-otp-app', {
      otpCode
    })
};

const Stats = {
  /* Common methods for dropdown */
  getClusterTagsList: () => superagent.get('/api/stats/cluster-tags-list'),

  /* Targeted Devices Dashboard */
  getTotalIfasSeenCount: () =>
    superagent.get('/api/stats/total-ifas-seen-count'),
  getActiveIFAsCount: () => superagent.get('/api/stats/active-ifas-count'),
  getActiveTargetedIFAsCount: () =>
    superagent.get('/api/stats/active-targeted-ifas-count'),
  getInactiveTargetedIfasCount: () =>
    superagent.get('/api/stats/inactive-targeted-ifas-count'),
  getActiveTargetedIfasClusterTagsList: (
    params?: TSearchFormParams & TPaginationParams
  ) =>
    superagent.get('/api/stats/active-targeted-ifas-cluster-tags-list', {
      params
    }),
  getActiveTargetedIfasList: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.get('/api/stats/active-targeted-ifas-list', { params }),
  getInactiveTargetedIfasList: (
    params?: TSearchFormParams & TPaginationParams
  ) => superagent.get('/api/stats/inactive-targeted-ifas-list', { params }),
  getDeviceEventsList: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.get('/api/stats/device-events-list', { params }),
  getActiveTargetedIfasTimePeriodCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-targeted-ifas-time-period-count', {
      params
    }),

  /* Targeted Locations Dashboard */
  getActiveIFAsCurrentDayCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-ifa-current-day-count', { params }),
  getActiveLOIsCurrentDayCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-loi-current-day-count', { params }),
  getActiveDOIsCurrentDayCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-doi-current-day-count', { params }),
  getActiveIFAsPastHourCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-ifa-past-hour-count', { params }),
  getActiveDOIsPastHourCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-doi-past-hour-count', { params }),
  getActiveLOIsPastHourCount: (params?: TSearchFormParams) =>
    superagent.get('/api/stats/active-loi-past-hour-count', { params })
};

const DeviceEvent = {
  getEventsList: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.post('/api/device-event/events-list', params),
  startAllEventsListDownload: (params?: TSearchFormParams) =>
    superagent.post('/api/device-event/start-csv-download', params),
  cancelAllEventsListDownload: (jobId: string) =>
    superagent.delete(`/api/device-event/cancel-download/${jobId}`),
  getAllEventsListProgress: (jobId: string) =>
    superagent.get(`/api/device-event/download-progress/${jobId}`),
  getCSVData: (jobId: string) =>
    superagent.get(`/api/device-event/download-csv/${jobId}`),
  getEventsCount: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.post('/api/device-event/events-count', params),
  getModelsList: (params?: TSearchFormParams) =>
    superagent.post('/api/device-event/models-list', params),
  getBundleIDsList: (params?: TSearchFormParams) =>
    superagent.post('/api/device-event/bundleids-list', params),
  getEventListForMap: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.post('/api/device-event/map-events-list', params),
  getMapArray: (params?: TSearchFormParams) =>
    superagent.post('/api/device-event/map-array', params),
  getDevicesList: () => superagent.get('/api/device-event/devices-list'),
  getLocationsList: () => superagent.get('/api/device-event/locations-list'),
  getCampaignsList: () => superagent.get('/api/device-event/campaigns-list'),
  getUnclusteredDevicesList: () =>
    superagent.get('/api/device-event/unclustered-devices-list'),
  getSourceTypesList: () =>
    superagent.get('/api/device-event/source-types-list')
};

const User = {
  getProfile: () => superagent.post('/api/users/profile'),
  updatePassword: (id: number, formData: IUserPasswordFormParams) =>
    superagent.put(`/api/users/${id}`, {
      user: formData
    }),
  /*
   * Administrative
   */
  getAll: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.get('/api/admin/users', { params }),
  getById: (id: number) => superagent.get(`/api/admin/users/${id}`),
  create: (params?: IUserFormParams) =>
    superagent.post('/api/admin/users', {
      user: params
    }),
  delete: (id: number) =>
    superagent.delete(`/api/admin/users`, { data: { user: { id } } }),
  update: (id: number, formData: IUserFormParams) =>
    superagent.put(`/api/admin/users/${id}`, {
      user: formData
    })
};

const SiteSetting = {
  getFirst: () => superagent.get('/api/admin/site-settings'),
  /*
   * Administrative
   */
  update: (formData: ISiteSettingParams) =>
    superagent.put('/api/admin/site-settings', {
      siteSetting: formData
    })
};

const Filters = {
  getAllFilters: (params: TPaginationParams) =>
    superagent.get('/api/filters/allFilters', { params }),
  getSingleFilter: (id: number) => superagent.get(`/api/filters/${id}`),
  createFilter: (campaigns: any) =>
    superagent.post('/api/filters/create', { campaigns }),
  getCurrentFilter: () => superagent.get(`/api/filters/current`)
};

const ClusterTags = {
  updateClusterTags: (clusterTags: any) =>
    superagent.put('/api/cluster-tags/update', { clusterTags }),
  getClusterTags: (params: { searchText?: string } & TPaginationParams) =>
    superagent.get('/api/cluster-tags', { params }),
  getAllClusterTags: () => superagent.get('/api/cluster-tags/all')
};

const AlertRules = {
  getAlertRules: (params?: { searchText?: string } & TPaginationParams) =>
    superagent.get('/api/alert-rule', { params }),
  createAlertRule: (alertRule: {
    name: string;
    locations: string[];
    devices: string[];
    status: boolean;
    clusterTag?: string;
  }) =>
    superagent.post('/api/alert-rule', {
      alertRule
    }),
  deleteAlertRule: (id: number) =>
    superagent.delete('/api/alert-rule', { data: { id: id } }),
  getById: (id: number) => superagent.get(`/api/alert-rule/${id}`),
  editAlertRule: (
    id: number,
    alertRule: {
      name?: string;
      locations?: string[];
      devices?: string[];
      status?: boolean;
      clusterTag?: string;
    }
  ) => superagent.put(`/api/alert-rule/${id}`, { alertRule })
};

const Alerts = {
  getAlerts: (
    params?: {
      searchText?: string;
      alertType?: string;
      acknowledged?: boolean;
      alertRuleName?: string;
    } & TPaginationParams
  ) => superagent.get('/api/alert', { params }),
  getTotalAlertCount: () => superagent.get('/api/alert/alert-count'),
  getUnacknowledgedAlertCount: () =>
    superagent.get('/api/alert/unacknowledged-alert-count'),
  acknowledgeAlert: (id: number) =>
    superagent.put('/api/alert/acknowledge', { id }),
  getAlertCountPastWeekByDay: () =>
    superagent.get('/api/alert/alert-count-past-week-by-day')
};

const Alias = {
  getAllAliases: () => superagent.get('/api/alias/all'),
  getAliases: (params: { searchText?: string } & TPaginationParams) =>
    superagent.get('/api/alias', { params }),
  updateAliases: (
    params: {
      ifa: string;
      alias: string;
    }[]
  ) => superagent.put('/api/alias', { aliases: params })
};

const ThresholdAlertRules = {
  getThresholdAlertRules: (
    params?: { searchText?: string } & TPaginationParams
  ) => superagent.get('/api/threshold-alert-rule', { params }),
  createThresholdAlertRule: (thresholdAlertRule: {
    name: string;
    status: boolean;
    baseline: number;
    varianceLimitPercentage: number;
    locationId: string;
  }) =>
    superagent.post('/api/threshold-alert-rule', {
      thresholdAlertRule
    }),
  deleteThresholdAlertRule: (id: number) =>
    superagent.delete('/api/threshold-alert-rule', { data: { id: id } }),
  getById: (id: number) => superagent.get(`/api/threshold-alert-rule/${id}`),
  editThresholdAlertRule: (
    id: number,
    thresholdAlertRule: {
      name?: string;
      status?: boolean;
      baseline?: number;
      varianceLimitPercentage?: number;
      locationId?: string;
    }
  ) => superagent.put(`/api/threshold-alert-rule/${id}`, { thresholdAlertRule })
};

const ThresholdAlerts = {
  getThresholdAlerts: (
    params?: {
      searchText?: string;
      acknowledged?: boolean;
      thresholdAlertRuleName?: string;
      isIncreased?: boolean;
    } & TPaginationParams
  ) => superagent.get('/api/threshold-alert', { params }),
  acknowledgeThresholdAlert: (id: number) =>
    superagent.put('/api/threshold-alert/acknowledge', { id })
};

const AuditTrail = {
  getAll: (params?: TSearchFormParams & TPaginationParams) =>
    superagent.get('/api/admin/audit-trail', { params })
};

const PersistentFilters = {
  getAllFilters: () => superagent.get('/api/persistent-filters/all-filters'),
  getCoordinateFilters: (
    params?: { searchText?: string } & TPaginationParams
  ) => superagent.get('/api/persistent-filters/coordinate-filters', { params }),
  postCoordinateFilter: (params: { value: string }) => {
    // console.log('params', params);
    return superagent.post(
      '/api/persistent-filters/coordinate-filters',
      params
    );
  },
  deleteCoordinateFilter: (coordinate: string) => {
    return superagent.delete(
      `/api/persistent-filters/coordinate-filters/${coordinate}`
    );
  },
  patchCoordinateFilter: (
    coordinate: number,
    params: { toExclude: number }
  ) => {
    return superagent.patch(
      `/api/persistent-filters/coordinate-filters/${coordinate}`,
      params
    );
  }
};

const apiAgent = {
  PersistentFilters,
  Auth,
  User,
  AuditTrail,
  SiteSetting,
  Filters,
  ClusterTags,
  Stats,
  DeviceEvent,
  AlertRules,
  Alerts,
  Alias,
  ThresholdAlertRules,
  ThresholdAlerts
};
export default apiAgent;
