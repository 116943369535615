import { ElementVariant } from '../interfaces';
import { StyledButton } from './Button';
import Modal from './Modal';
import Paragraph from './Paragraph';

const DownloadCSVModal = ({
  show,
  setShow,
  jobId
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  jobId: string | null;
}) => {
  const handleDownload = () => {
    setShow(!show);
  };

  return (
    <Modal showModal={show} setShowExternal={setShow}>
      <Modal.Display className="fixed inset-0 flex items-center justify-center">
        <Modal.Header showBorder>
          <Modal.Title>Download in Progress for job: {jobId}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="pt-5 overflow-y-auto overflow-x-hidden h-32"
          padding={false}
        >
          <div className="block w-full px-5 mb-5">
            <Paragraph>
              Your download is starting. If you navigate away from this page,
              the download may stop.
              <br />
              Please wait until the download completes or open a new tab if you
              need to continue browsing.
            </Paragraph>
          </div>
        </Modal.Body>

        <Modal.Footer showBorder>
          <div className="block w-full text-center">
            <StyledButton
              variant={ElementVariant.primaryAltTwo}
              className="mr-5"
              onClick={handleDownload}
            >
              Continue Download
            </StyledButton>
          </div>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

export default DownloadCSVModal;
