import moment from 'moment';

// import

import {
  ContainerFull,
  Modal,
  PageSubDescription,
  PageTitle,
  Paragraph,
  StyledButton
} from '../../components';

import { ElementVariant } from '../../interfaces';
import DeviceEventsTable from './components/DeviceEventsTable';
import Stats from './components/Stats';
// import ActiveTargetedIFAsClusterTagsListTable from './components/ActiveTargetedIFAsClusterTagsListTable';
// import InactiveTargetedIfasListTable from './components/InactiveTargetedIFAsTable';
// import ActiveTargetedIfasListTable from './components/ActiveTargetedIFAsTable';
// import EventsCountLineChart from './components/EventsCountLineChart';
// import WordCloud from './components/WordCloud';
// import DonutChart from './components/DonutChart';
import MapChart from './components/MapChart/index';

import { useEffect, useState } from 'react';
// import { Formik } from 'formik';
import _ from 'lodash';
import apiAgent from '../../api/apiAgent';
import SlidingPanel from '../../components/SlidingPanel';
import { TPaginationParams, TSearchFormParams } from '../../interfaces/api';
import { toastHandler } from '../../utils';
import { Campaign, Location } from '../campaigns/filtersUtil';
import DeviceEventsFilter from './components/DeviceEventsFilter';

// const transformClusterTagsListForOptions = (
//   list: any[]
// ): { value: string | number; label: string }[] => {
//   return list.map((obj: any) => ({
//     label: obj.cluster_tag_id,
//     value: obj.cluster_tag_id
//   }));
// };

//RZ Sample Data
import { useAtom } from 'jotai';
import { otherParamsAtom } from '../../atoms/dashboardStore';
import DownloadCSVModal from '../../components/DownloadCSVModal';
import DeviceEventsCoordinatesFilter from './components/DeviceEventsCoordinatesFilter';
import rz_dois from './rz_data/rz_dois';
import rz_lois from './rz_data/rz_lois';

const TargetedLocationsDashboard = (): JSX.Element => {
  // const [searchText, setSearchText] = useState('');
  // const [searchClusterTags, setSearchClusterTags] = useState<string[]>([]);

  const [searchParams, setSearchParams] = useState<
    TSearchFormParams & TPaginationParams
  >({ searchCoordinatesDecimals: 4 });
  const [coordinatesSearchParams, setCoordinatesSearchParams] = useState({});
  const [aoiInt, setAoiInt] = useState<boolean>(false);
  const [locationsList, setLocationsList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [totalEvents, setTotalEventsCount] = useState(0);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [isPersistentFiltersPanelOpen, setIsPersistentFiltersPanelOpen] =
    useState<boolean>(false);
  const [showPersistentFiltersExitModal, setShowPersistentFiltersExitModal] =
    useState<boolean>(false);
  //filter states
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('day').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationFilterType, setLocationFilterType] = useState<string>('or');
  const [locationValues, setLocationValues] = useState<any>([]);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [locationInt, setLocationInt] = useState<boolean>(false);
  const [deviceValues, setDeviceValues] = useState<any>([]);
  const [deviceOptions, setDeviceOptions] = useState<any[]>([]);
  const [deviceInt, setDeviceInt] = useState<boolean>(false);
  const [campaignValues, setCampaignValues] = useState<any>([]);
  const [campaignOptions, setCampaignOptions] = useState<any>([]);
  const [campaignInt, setCampaignInt] = useState<boolean>(false);
  const [sourceTypeValues, setSourceTypeValues] = useState<any>([]);
  const [sourceTypeOptions, setSourceTypeOptions] = useState<any>([]);
  const [sourceTypeInt, setSourceTypeInt] = useState<boolean>(false);
  const [devicesText, setDevicesTexts] = useState<string[]>([]);
  const [coordinatesDecimals, setCoordinatesDecimals] = useState<number>(4);
  const [filterChanged, setFilterChanged] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [jobDownloadId, setJobDownloadId] = useState<string | null>(null);
  const [otherParams, setOtherParams] = useAtom(otherParamsAtom);
  //State for going to Coordinates from click from table
  const [coordinatesFromEventRecords, setCoordinatesFromEventRecords] =
    useState<string[]>([]);
  const unclusteredOption = {
    label: '*UNCLUSTERED*',
    value: '*UNCLUSTERED*'
  };

  const [showMap, setShowMap] = useState<boolean>(false);

  const fetchDropDowns = async (): Promise<void> => {
    try {
      setIsLoading(true);

      //devices options
      const clusterTags = await apiAgent.ClusterTags.getAllClusterTags();
      let newDeviceOptions: any[] = [];

      newDeviceOptions.push(unclusteredOption);

      //get devices from cluster tags
      const aliases = await (
        await apiAgent.Alias.getAllAliases()
      ).data.data.aliases;

      clusterTags.data.data.clusterTags.forEach((clusterTag: any) => {
        newDeviceOptions.push({
          label: clusterTag.clusterTagId,
          options: clusterTag.devices.map((device: any): any => {
            const alias = aliases.find((e: any) => {
              return e.ifa === device.ifa;
            });
            return {
              label: `${device.ifa} ${alias ? `(${alias.alias})` : ''}`,
              value: { value: device.ifa, groupLabel: clusterTag.clusterTagId }
            };
          })
        });
      });

      //Added for RZ sampledata
      newDeviceOptions.push({
        label: 'rz_eval_dois',
        options: rz_dois.map((device: any): any => {
          return {
            label: device.DOI,
            value: { value: device.DOI, groupLabel: 'rz_eval_dois' }
          };
        })
      });

      // get unclustered devices
      // const unclusteredDevices = await (
      //   await apiAgent.DeviceEvent.getUnclusteredDevicesList()
      // ).data.data.unclusteredDevicesList;
      // newDeviceOptions.push({
      //   label: '*unclustered*',
      //   options: unclusteredDevices.map((device: any): IDeviceOptions => {
      //     const alias = aliases.find((e: any) => {
      //       return e.ifa === device.ifa;
      //     });
      //     return {
      //       label: `${device.ifa} ${alias ? `(${alias.alias})` : ''}`,
      //       value: { value: device.ifa, groupLabel: '*unclustered*' }
      //     };
      //   })
      // });

      setDeviceOptions(newDeviceOptions);

      //location options
      let result = await apiAgent.DeviceEvent.getLocationsList();
      let newLocationOptions: any[] = [];
      result.data.data.locations.forEach((location: Location) => {
        newLocationOptions.push({
          label: location.locationId,
          value: location.locationId
        });
      });

      //Added for RZ sampledata
      rz_lois.forEach((location: any) => {
        newLocationOptions.push({
          label: location.LOI_name,
          value: location.LOI_name
        });
      });

      setLocationOptions(newLocationOptions);

      //campaign options
      result = await apiAgent.DeviceEvent.getCampaignsList();
      let newCampaignOptions: any[] = [];
      result.data.data.campaigns.forEach((campaign: Campaign) => {
        newCampaignOptions.push({
          label: campaign.campaignId,
          value: campaign.campaignId
        });
      });

      //Added for RZ sampledata
      newCampaignOptions.push({
        label: 'rz_eval',
        value: 'rz_eval'
      });

      setCampaignOptions(newCampaignOptions);

      //source type options
      result = await apiAgent.DeviceEvent.getSourceTypesList();
      let newSourceTypeOptions: any[] = [];
      result.data.data.sourceTypes.forEach((sourceType: any) => {
        newSourceTypeOptions.push({
          label: sourceType.name,
          value: sourceType.id
        });
      });

      setSourceTypeOptions(newSourceTypeOptions);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterClickFromMap = (value: string, type: string) => {
    // console.log('handleFilterClickFromMap', value, type);

    let filterValues = {};
    switch (type) {
      case 'LOCATION':
        filterValues = {
          searchLOIs:
            searchParams.searchLOIs?.length != null
              ? searchParams.searchLOIs?.concat([value])
              : [value]
        };
        setLocationValues(
          locationValues.length != null
            ? locationValues.concat([{ value, label: value }])
            : [{ value, label: value }]
        );
        break;
      case 'CLUSTER':
        // console.log(`clusterTag: ${value}`);
        filterValues = {
          searchOtherParams: [
            {
              columnName: 'cluster_tag',
              values: [value],
              intersection: false
            }
          ]
        };
        setOtherParams([
          {
            columnName: 'cluster_tag',
            values: [value],
            intersection: false
          },
          ...otherParams
        ]);
        break;
      case 'ALIAS':
        filterValues = {
          searchIFAs:
            searchParams.searchIFAs?.length != null
              ? searchParams.searchIFAs?.concat([value])
              : [value]
        };
        setDeviceValues(
          deviceValues.length != null
            ? deviceValues.concat([
                { label: value, value: { groupLabel: 'N.A', value } }
              ])
            : [{ label: value, value: { groupLabel: 'N.A', value } }]
        );
        break;
    }

    setSearchParams({
      ...searchParams,
      ...filterValues
    });
  };

  const applyFilters = async (searchFormParams: any) => {
    const {
      setIsLoading,
      startDate,
      endDate,
      locationFilterType,
      deviceValues,
      unclusteredSelected,
      devicesText,
      deviceInt,
      locationValues,
      locationInt,
      campaignValues,
      campaignInt,
      sourceTypeValues,
      sourceTypeInt,
      coordinatesDecimals,
      coordinatesSearchParams,
      setFilterChanged
    } = searchFormParams;

    try {
      setIsLoading(true);

      //parse filters
      let params: TSearchFormParams & TPaginationParams = {};
      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }
      params.searchLOIsBy = locationFilterType;
      if (deviceValues.length > 0) {
        let newDevicesValues = _.cloneDeep(deviceValues);
        newDevicesValues = newDevicesValues.map(
          (devicesOption: any) => devicesOption.value.value
        );
        params.searchIFAs = newDevicesValues;
        // if (!newDevicesValues.includes('*')) {
        //   params.searchIFAs = newDevicesValues;
        // }
      }
      if (devicesText.length > 0) {
        let newDevicesText = params.searchIFAs ?? [];
        newDevicesText = newDevicesText.concat(devicesText);
        params.searchIFAs = newDevicesText;
      }

      if (unclusteredSelected) {
        params.searchUnclusteredIFAs = true;
      }

      if (locationValues.length > 0) {
        let newLocationsValues = _.cloneDeep(locationValues);
        newLocationsValues = newLocationsValues.map(
          (locationOption: any) => locationOption.value
        );
        params.searchLOIs = newLocationsValues;
      }

      if (campaignValues.length > 0) {
        let newCampaignsValues = _.cloneDeep(campaignValues);
        newCampaignsValues = newCampaignsValues.map(
          (campaignOption: any) => campaignOption.value
        );
        params.searchCampaigns = newCampaignsValues;
      }

      if (sourceTypeValues.length > 0) {
        let newSourceTypeValues = _.cloneDeep(sourceTypeValues);
        newSourceTypeValues = newSourceTypeValues.map(
          (sourceTypeOption: any) => sourceTypeOption.value
        );
        params.searchSourceTypes = newSourceTypeValues;
      }

      //Single Rect draw version
      // if (coordinatesSearchParams.latFrom) {
      //   const { latFrom, latTo, lngFrom, lngTo } = coordinatesSearchParams;
      //   params = { ...params, latFrom, latTo, lngFrom, lngTo };
      // }

      //Multi Rects / Poly draw version
      if (coordinatesSearchParams.length > 0) {
        params = {
          ...params,
          searchAOIs: coordinatesSearchParams.map((e: any) => e.coordsEntry)
        };
      }
      params.searchIFAsInt = deviceInt;
      params.searchLOIsInt = locationInt;
      params.searchCampaignsInt = campaignInt;
      params.searchSourceTypesInt = sourceTypeInt;
      params.searchAOIsInt = aoiInt;
      params.searchCoordinatesDecimals = coordinatesDecimals;

      if (otherParams && otherParams.length > 0) {
        let newOtherParams = _.cloneDeep(otherParams);
        newOtherParams = newOtherParams.filter(
          (param) => param.columnName !== '' || param.values.length > 0
        );
        newOtherParams = newOtherParams.map((param) => {
          return {
            ...param,
            values: param.values.filter((value) => value !== '')
          };
        });
        if (newOtherParams.length > 0) {
          params.searchOtherParams = newOtherParams;
        }
        // check for valid param
        for (const otherParam of newOtherParams) {
          if (otherParam.columnName === '') {
            toastHandler({
              messages: 'Column Name cannot be empty',
              toastOptions: { type: 'error' }
            });
            return;
          }
          if (otherParam.values.length <= 0) {
            toastHandler({
              messages: 'Values cannot be empty',
              toastOptions: { type: 'error' }
            });
            return;
          }
        }
      }

      setFilterChanged(false);
      setIsPanelOpen(false);
      // console.log(`params: ${JSON.stringify(params, null, 4)}`);
      setSearchParams(params);
      setShowMap(true);
      toastHandler({
        messages: `Filters applied!`,
        toastOptions: { type: 'success' }
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const [clusterTagsOptions, setClusterTagsOptions] = useState<
  //   | {
  //       value: string | number;
  //       label: string;
  //     }[]
  //   | null
  // >([]);

  const fetchList = async (): Promise<void> => {
    try {
      // setIsActiveIFAsListLoading(true);
      // const { data } = await apiAgent.Stats.getAllClusterTagsList();

      // setClusterTagsOptions(
      //   transformClusterTagsListForOptions(data?.data?.clusterTagsList || [])
      // );

      const locationsListResponse =
        await apiAgent.DeviceEvent.getLocationsList();
      // console.log(
      //   `locations at index is ${JSON.stringify(locationsList.data, null, 2)}`
      // );

      const locationsList = locationsListResponse.data.data.locations;

      //Added for RZ sampledata
      rz_lois.forEach((location: any, index: number) => {
        locationsList.push({
          id: index + -999,
          locationId: location.LOI_name,
          longitude: location.Long,
          latitude: location.Lat,
          radius: location.Radius,
          status: false
        });
      });

      setLocationsList(locationsList);

      const devicesList = await apiAgent.DeviceEvent.getDevicesList();

      // console.log(
      //   `devicesList at index is ${JSON.stringify(devicesList.data, null, 2)}`
      // );

      setDevicesList(devicesList.data.data.devices);
    } catch (error: any) {
      toastHandler({
        messages: error?.response?.data?.message ?? error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      // setIsActiveIFAsListLoading(false);
    }
  };

  const TipsModal = () => {
    return (
      <Modal>
        <Modal.Toggle>
          <StyledButton variant={ElementVariant.secondary}>
            <i className="fa-solid fa-circle-info"></i> Useful Tips
          </StyledButton>
        </Modal.Toggle>
        <Modal.Display>
          <Modal.Header showBorder>
            <Modal.Title>Useful Tips</Modal.Title>
          </Modal.Header>

          <Modal.Body className="pt-5" padding={false}>
            <div className="block w-full px-5 mb-5">
              <Paragraph>
                <h3>Metric Values</h3>
                <ul>
                  <li>
                    - Shows the Active IFAs/LOIs/DOIs for the current day (left
                    3 metrics) and current hour (right 3 metrics)
                  </li>
                </ul>
                <br />
                <h3>Filter Options</h3>
                <ul>
                  <li>
                    - To customise the Filter Options, click on the{' '}
                    <i className="fa-solid fa-filter px-2"></i> icon to open up
                    the panel.
                  </li>
                  <li>
                    - To close the Panel, simply click outside the panel, the X
                    button located on the top right of the panel, or cancel
                    button at the bottom.
                  </li>
                  <li>
                    - Each filter options (except Date Range) has a toggable
                    "Intersection" button that is turned on (Green color) by
                    default. This button allows the user to specify the columns
                    that would be intersecting(Green) and the columns would be
                    union-like (Grey Color).
                  </li>
                  <li>
                    - After clicking Apply, the Filter Options panel will be
                    closed automatically.
                  </li>
                  <li>
                    - Note that overly complex filter options will result in
                    longer search duration.
                  </li>
                </ul>
                <br />
                <h3>Interactive Map</h3>
                <ul>
                  <li>
                    - Size for each node determines the number of DEVICES
                    captured at the coordinate. Larger nodes denotes more
                    devices captured at the coordinate.
                  </li>
                  <li>
                    - Opacity for each node determines the number of EVENTS
                    captured at the coordinate. The darker a node, the more
                    events captured at that point.
                  </li>
                  <li>
                    - To create custom pins on map, double click on any spot to
                    create a pin. Clicking on the pin will show the coordinates,
                    as well as the option to remove the pin.
                  </li>
                  <li>
                    - Using the <i className="fa-solid fa-square-full"></i> icon
                    toolbar button located on the top left of the map, you can
                    draw and create a Area of Interest (AOI) which denotes a
                    coordinates range as a Filter Option, which will appear at
                    the Filter Options panel for filtering.
                  </li>
                  <li>
                    - To remove each AOI, use the{' '}
                    <i className="fa-solid fa-trash"></i> icon location on the
                    top left of the map, just below the AOI creation icon. You
                    can click on each AOI on the map to remove it, then click
                    "Save". You can also remove all AOIs by selecting "Clear
                    All".
                  </li>
                  <li>
                    - Map loads progressively, indicated just below the bottom
                    left of the map visual. During loading, map visual itself
                    may feel unresponsive. This is due to the map being updated
                    on the go.
                  </li>
                  <li>
                    - After loading is complete, the number of records retrieved
                    is shown. You may click on "Load More" to trigger retrieval
                    of additional map points if the current retrieved data set
                    has little unique map points.
                  </li>
                </ul>
                <br />
                <h3>Events Records</h3>
                <ul>
                  <li>
                    - Each page shows 1000 entries by default. You can change
                    the entries per page using the "Entries Per Page" dropdown
                    option at the bottom of the listing table.
                  </li>
                  <li>
                    - You can zoom to the location on map for each entry by
                    clicking on the <i className="fa-solid fa-location-dot"></i>{' '}
                    icon at the front of each row. This will also create a pin
                    at the location for clarity. (Can be removed the same way as
                    a custom pin.) <b>Important note:</b> This feature only pan
                    and zoom to the location for the event entry. Depending on
                    the sequence of event and max plot point limit on the map,
                    the dot may not be on the map when panned and zoomed to the
                    location.
                  </li>
                  <li>
                    - You can download the current page's records using the
                    "Download CSV" button. Data will be saved in CSV format.
                  </li>
                </ul>
              </Paragraph>
            </div>
          </Modal.Body>

          <Modal.Footer showBorder>
            <div className="block w-full text-center">
              <Modal.Toggle>
                <StyledButton
                  variant={ElementVariant.primaryAltTwo}
                  className="mr-5"
                >
                  Close
                </StyledButton>
              </Modal.Toggle>
            </div>
          </Modal.Footer>
        </Modal.Display>
      </Modal>
    );
  };

  useEffect(() => {
    fetchList();
    fetchDropDowns();
  }, []);

  // const formikSchema = {
  //   initialValues: {
  //     searchText: '',
  //     clusterTags: ''
  //   },
  //   validationSchema: Yup.object({
  //     searchText: Yup.string()
  //   }),
  //   onSubmit: async (values: any) => {
  //     setSearchText(values.searchText);
  //     setSearchClusterTags(values.clusterTags);
  //   },
  //   validateOnChange: true
  // };

  return (
    <div>
      <SlidingPanel isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen}>
        <DeviceEventsFilter
          coordinatesSearchParams={coordinatesSearchParams}
          setCoordinatesSearchParams={(params) => {
            setCoordinatesSearchParams(params);
          }}
          aoiInt={aoiInt}
          setAoiInt={setAoiInt}
          handleOnCancel={() => setIsPanelOpen(false)}
          applyFilters={applyFilters}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          locationFilterType={locationFilterType}
          setLocationFilterType={setLocationFilterType}
          locationValues={locationValues}
          setLocationValues={setLocationValues}
          locationOptions={locationOptions}
          setLocationOptions={setLocationOptions}
          locationInt={locationInt}
          setLocationInt={setLocationInt}
          deviceValues={deviceValues}
          setDeviceValues={setDeviceValues}
          deviceOptions={deviceOptions}
          setDeviceOptions={setDeviceOptions}
          deviceInt={deviceInt}
          setDeviceInt={setDeviceInt}
          campaignValues={campaignValues}
          setCampaignValues={setCampaignValues}
          campaignOptions={campaignOptions}
          setCampaignOptions={setCampaignOptions}
          campaignInt={campaignInt}
          setCampaignInt={setCampaignInt}
          sourceTypeValues={sourceTypeValues}
          setSourceTypeValues={setSourceTypeValues}
          sourceTypeOptions={sourceTypeOptions}
          setSourceTypeOptions={setSourceTypeOptions}
          sourceTypeInt={sourceTypeInt}
          setSourceTypeInt={setSourceTypeInt}
          devicesText={devicesText}
          setDevicesTexts={setDevicesTexts}
          coordinatesDecimals={coordinatesDecimals}
          setCoordinatesDecimals={setCoordinatesDecimals}
          filterChanged={filterChanged}
          setFilterChanged={setFilterChanged}
          unclusteredOption={unclusteredOption}
        />
      </SlidingPanel>
      <SlidingPanel
        isPanelOpen={isPersistentFiltersPanelOpen}
        setIsPanelOpen={setIsPersistentFiltersPanelOpen}
      >
        <DeviceEventsCoordinatesFilter
          showPersistentFiltersExitModal={showPersistentFiltersExitModal}
          setShowPersistentFiltersExitModal={setShowPersistentFiltersExitModal}
          isLoading={isLoading}
          handleOnCancel={() => setIsPersistentFiltersPanelOpen(false)}
          setIsLoading={setIsLoading}
        />
      </SlidingPanel>
      <ContainerFull>
        <PageTitle>
          Locations Dashboard {`${moment().format('DD-MMM-YYYY')}`}
        </PageTitle>

        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-6 content-center">
            <PageSubDescription>
              Locations centric dashboard showing device events map plotting at
              targeted locations and other useful information.
            </PageSubDescription>
          </div>
          <div className="col-span-2 content-center"></div>
          <div className="col-span-2 justify-self-end mr-0">
            <TipsModal />
          </div>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-12 gap-4 mb-4">
            <Stats searchParams={searchParams} />
          </div>

          <div className="grid grid-cols-12 gap-4">
            {/* <div className="col-span-3 ">
              <DeviceEventsFilter
                coordinatesSearchParams={coordinatesSearchParams}
                handleOnApply={handleOnFiltersApply}
              />
            </div> */}
            <div className="col-span-12 rounded-md bg-background-alt pt-4 pb-9 px-5 ">
              {showMap ? (
                <MapChart
                  searchParams={searchParams}
                  locationsList={locationsList}
                  devicesList={devicesList}
                  totalEvents={totalEvents}
                  coordinatesSearchParams={coordinatesSearchParams}
                  setCoordinatesSearchParams={(params) => {
                    setFilterChanged(true);
                    setCoordinatesSearchParams(params);
                  }}
                  coordinatesFromEventRecords={coordinatesFromEventRecords}
                  setCoordinatesFromEventRecords={
                    setCoordinatesFromEventRecords
                  }
                  handleFilterClickFromMap={handleFilterClickFromMap}
                />
              ) : (
                <div className="flex justify-center">
                  <StyledButton onClick={() => setShowMap(true)}>
                    Load Map
                  </StyledButton>
                </div>
              )}
            </div>
            {/* <div className="col-span-2">
            <EventsCountLineChart
              searchClusterTags={searchClusterTags}
              searchText={searchText}
            />
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Models (Top 10)
                <WordCloud />
              </div>
              <DonutChart title="Bundle IDs" />

              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Device Name
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                OS
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Platforms
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Application
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Carriers
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                Connections
              </div>
              <div className="col-span-1 rounded-md bg-background-alt py-4 px-5">
                User Agent
              </div>
            </div>
          </div> */}
            {/* <ActiveTargetedIfasListTable />

          <InactiveTargetedIfasListTable /> */}
          </div>

          {/* <div className="grid grid-cols-12 gap-4 mb-4">
            <ActiveTargetedIFAsClusterTagsListTable
            searchClusterTags={searchClusterTags}
            searchText={searchText}
          />

          
          </div> */}

          <div className="mt-4 mb-4">
            <DeviceEventsTable
              searchParams={searchParams}
              setJobDownloadId={setJobDownloadId}
              jobDownloadId={jobDownloadId}
              setShowCSVModal={setShowCSVModal}
              devicesList={devicesList}
              totalEvents={totalEvents}
              setTotalEventsCount={setTotalEventsCount}
              setCoordinatesFromEventRecords={setCoordinatesFromEventRecords}
            />
            <DownloadCSVModal
              show={showCSVModal}
              setShow={setShowCSVModal}
              jobId={jobDownloadId}
            />
          </div>
        </div>
      </ContainerFull>
      <div
        className="fixed top-1/4 left-0 flex flex-col justify-center gap-4"
        style={{ zIndex: 1000 }}
      >
        <StyledButton
          className="text-lg"
          style={{ zIndex: 1000 }}
          onClick={() => {
            !!isPersistentFiltersPanelOpen &&
              setIsPersistentFiltersPanelOpen(false);
            setIsPanelOpen(true);
          }}
          variant={ElementVariant.warning}
        >
          <i className="fa-solid fa-filter px-2"></i>
        </StyledButton>
        <StyledButton
          className="text-lg"
          style={{ zIndex: 1000 }}
          onClick={() => {
            !!isPanelOpen && setIsPanelOpen(false);
            setIsPersistentFiltersPanelOpen(true);
          }}
          variant={ElementVariant.secondary}
        >
          <i className="fa-solid fa-arrow-up-wide-short px-2"></i>
        </StyledButton>
      </div>
    </div>
  );
};

export default TargetedLocationsDashboard;
