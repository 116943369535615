import L, { LatLngBoundsLiteral } from 'leaflet';
import millify from 'millify';
import { useEffect, useRef, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

const CustomAreaDraw = ({
  currentRects,
  setCurrentRects,
  coordinatesSearchParams,
  setCoordinatesSearchParams
}: {
  currentRects: any;
  setCurrentRects: (rects: L.FeatureGroup) => void;
  coordinatesSearchParams?: any[];
  setCoordinatesSearchParams: (latlngs: any[]) => void;
}): JSX.Element => {
  const [rectGroup, setRectGroup] = useState<L.FeatureGroup | null>(null);
  const featureGroupRef = useRef<L.FeatureGroup>(null);
  // const presetAddedRef = useRef(false);
  // const presetRectangles = [
  //   [
  //     [2.4249, 102.5107],
  //     [2.593, 102.6411]
  //   ],
  //   [
  //     [2.2692, 102.0903],
  //     [2.2994, 102.1237]
  //   ],
  //   [
  //     [2.3013, 103.908],
  //     [2.3519, 103.9509]
  //   ],
  //   [
  //     [4.215, 100.5897],
  //     [4.2445, 100.6247]
  //   ],
  //   [
  //     [3.7621, 103.1893],
  //     [3.7903, 103.2123]
  //   ],
  //   [
  //     [5.675912860874002, 100.48207158226197],
  //     [5.664083471319749, 100.49125546603389]
  //   ],
  //   [
  //     [5.907352778835924, 102.22380560871234],
  //     [5.895173109114079, 102.24481199369426]
  //   ],
  //   [
  //     [2.050545084694499, 103.29864042585429],
  //     [2.0365844853617197, 103.3090389786277]
  //   ],
  //   [
  //     [3.0658382308471457, 101.71583693466744],
  //     [3.0464314166386033, 101.72486960890846]
  //   ]
  // ];

  // used for preset rectangles
  // useEffect(() => {
  //   if (featureGroupRef.current && !presetAddedRef.current) {
  //     const fg = featureGroupRef.current;
  //     const newCoordinatesSearchParams: any[] = [];

  //     presetRectangles.forEach((bounds) => {
  //       const rect = L.rectangle(bounds as LatLngBoundsLiteral, {
  //         weight: 2,
  //         color: '#283F3B',
  //         fill: true,
  //         fillColor: '#99DDC8'
  //       });
  //       fg.addLayer(rect);

  //       const coordsEntry = {
  //         latFrom: millify(bounds[0][0], { precision: 4 }),
  //         latTo: millify(bounds[1][0], { precision: 4 }),
  //         lngFrom: millify(bounds[0][1], { precision: 4 }),
  //         lngTo: millify(bounds[1][1], { precision: 4 })
  //       };
  //       newCoordinatesSearchParams.push({ type: 'rect', coordsEntry });
  //     });

  //     setCoordinatesSearchParams(newCoordinatesSearchParams);
  //     presetAddedRef.current = true;
  //   }
  // }, [setCoordinatesSearchParams]);

  useEffect(() => {
    if (rectGroup) {
      setCurrentRects(rectGroup);
    }
  }, [rectGroup, setCurrentRects]);

  useEffect(() => {
    if (featureGroupRef.current && currentRects) {
      currentRects.eachLayer((layer: any) => {
        featureGroupRef.current?.addLayer(layer);
      });
    }
  }, [currentRects]);

  useEffect(() => {
    if (featureGroupRef.current && coordinatesSearchParams) {
      const fg = featureGroupRef.current;
      fg.clearLayers();

      if (
        Array.isArray(coordinatesSearchParams) &&
        coordinatesSearchParams.length > 0
      ) {
        coordinatesSearchParams.forEach((shape) => {
          if (shape.type === 'rect') {
            const { latFrom, latTo, lngFrom, lngTo } = shape.coordsEntry;
            const bounds: LatLngBoundsLiteral = [
              [latFrom, lngFrom],
              [latTo, lngTo]
            ];
            const rect = L.rectangle(bounds, {
              weight: 2,
              color: '#283F3B',
              fill: true,
              fillColor: '#99DDC8'
            });
            fg.addLayer(rect);
          } else if (shape.type === 'poly') {
            const latlngs = shape.coordsEntry.map(
              (coord: any) => [coord.lat, coord.lng] as [number, number]
            );
            const polygon = L.polygon(latlngs, {
              weight: 2,
              color: '#8B4000',
              fill: true,
              fillColor: '#FFA500'
            });
            fg.addLayer(polygon);
          }
        });
      }
    }
  }, [coordinatesSearchParams]);

  const handleDrawShape = (event: any) => {
    saveCurrentRects();
  };

  const handleDeleteRect = (event: any) => {
    saveCurrentRects();
  };

  const isRectangle = (latlngs: any[]) => {
    if (latlngs.length !== 5) return false;
    const [bl, br, tr, tl, closing] = latlngs;
    if (bl[0] !== closing[0] || bl[1] !== closing[1]) return false;
    const dist = (p1: any, p2: any) =>
      Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
    return (
      dist(bl, br) === dist(tl, tr) &&
      dist(bl, tl) === dist(br, tr) &&
      dist(bl, br) > 0 &&
      dist(bl, tl) > 0
    );
  };

  const saveCurrentRects = () => {
    const rects = featureGroupRef.current;
    if (rects) setRectGroup(rects);

    const coordinatesArray: any[] = [];

    rects?.eachLayer((layer: any) => {
      const geoJson = layer.toGeoJSON();

      const latlngs = geoJson.geometry.coordinates[0];
      if (isRectangle(latlngs)) {
        const coordsEntry = {
          latFrom: millify(latlngs[0][1], { precision: 4 }),
          latTo: millify(latlngs[1][1], { precision: 4 }),
          lngFrom: millify(latlngs[0][0], { precision: 4 }),
          lngTo: millify(latlngs[2][0], { precision: 4 })
        };
        coordinatesArray.push({ type: 'rect', coordsEntry });
      } else {
        const latlngPairs = latlngs.map((latlng: any) => ({
          lat: millify(latlng[1], { precision: 4 }),
          lng: millify(latlng[0], { precision: 4 })
        }));
        coordinatesArray.push({ type: 'poly', coordsEntry: latlngPairs });
      }
    });
    setCoordinatesSearchParams(coordinatesArray);
  };

  return (
    <FeatureGroup ref={featureGroupRef}>
      <EditControl
        position="topleft"
        onCreated={handleDrawShape}
        onDeleted={handleDeleteRect}
        onMounted={() => setRectGroup(featureGroupRef.current)}
        edit={{
          edit: false,
          delete: false
        }}
        draw={{
          polygon: {
            allowIntersection: false,
            drawError: {
              color: '#e1e100',
              message: "<strong>Oh snap!<strong> you can't draw that!"
            },
            shapeOptions: {
              weight: 2,
              clickable: true,
              color: '#8B4000',
              fill: true,
              fillColor: '#FFA500'
            },
            metric: ['m', 'km']
          },
          circle: false,
          marker: false,
          polyline: false,
          circlemarker: false,
          rectangle: {
            shapeOptions: {
              weight: 2,
              clickable: true,
              color: '#283F3B',
              fill: true,
              fillColor: '#99DDC8'
            },
            metric: ['m', 'km']
          }
        }}
      />
    </FeatureGroup>
  );
};

export default CustomAreaDraw;
