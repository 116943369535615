import classNames from 'classnames';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

interface ChildrenProps {
  children: React.ReactNode;
}

const ModalContext = createContext<{ show: boolean; setShow: any } | null>(
  null
);

const useModalContext = (): {
  show: boolean;
  setShow: any;
} => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'Modal components cannot be rendered outside the Modal component'
    );
  }

  return context;
};

const Toggle = ({ children }: ChildrenProps): JSX.Element => {
  const { setShow } = useModalContext();
  const handleToggle = () => setShow((prevShow: boolean) => !prevShow);
  return (
    <div className="inline-block" onClick={handleToggle}>
      {children}
    </div>
  );
};

const Title = ({ children }: ChildrenProps): JSX.Element => {
  return <h1 className="text-xl font-semibold">{children}</h1>;
};

const Header = ({
  children,
  showBorder = false
}: ChildrenProps & { showBorder?: boolean }): JSX.Element => {
  const { setShow } = useModalContext();
  const handleClose = () => setShow(false);
  return (
    <div
      className={classNames(
        'bg-background-alt flex items-center justify-between py-3 px-5 rounded-t',
        { 'border-b border-solid border-gray-300': showBorder }
      )}
    >
      {children}
      <button
        type="button"
        onClick={handleClose}
        className="ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:opacity-100"
      >
        <span className="bg-transparent text-white text-3xl block outline-none focus:outline-none">
          ×
        </span>
      </button>
    </div>
  );
};

const Body = ({
  children,
  className,
  padding = true
}: { className?: string; padding?: boolean } & ChildrenProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'relative flex-auto',
        { 'px-5': padding },
        className
      )}
    >
      {children}
    </div>
  );
};

const Footer = ({
  children,
  showBorder = false
}: ChildrenProps & { showBorder?: boolean }): JSX.Element => {
  return (
    <div
      className={classNames('flex items-center justify-end py-3 px-5', {
        'border-t border-solid border-gray-300': showBorder
      })}
    >
      {children}
    </div>
  );
};

interface IModalDisplayProps extends ChildrenProps {
  tailwindWidth?: string;
  className?: string;
}

const ModalDisplay = ({
  children,
  tailwindWidth,
  className
}: IModalDisplayProps): JSX.Element | null => {
  const { show } = useModalContext();

  useEffect(() => {
    if (show) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [show]);

  // Disable to prevent accidental user clicks
  // const handleClose = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   if (e.currentTarget === e.target) {
  //     setShow(false);
  //   }
  // };

  const dialogClass =
    'bg-background-alt relative z-40 border-0 rounded-lg shadow-lg outline-none focus:outline-none mt-10 mx-auto ' +
    (tailwindWidth || 'w-1/2');

  return show ? (
    <Fragment>
      <div
        id="modal"
        className={`z-20 overflow-y-auto overflow-x-hidden block fixed inset-0 ${className}`}
        // onClick={handleClose}
        style={{ zIndex: 1002 }}
      >
        <div id="modal-dialog" className={dialogClass}>
          {children}
        </div>
      </div>
      <div className="z-10 opacity-80 fixed inset-0 bg-black" />
    </Fragment>
  ) : null;
};

type ModalContainerChildren = { Display?: typeof ModalDisplay } & {
  Title?: typeof Title;
} & { Header?: typeof Header } & {
  Body?: typeof Body;
} & { Footer?: typeof Footer } & { Toggle?: typeof Toggle };
/**
 * Please use showModal and setShowExternal if you intend to use parent component
 * to handle the open/closing of modal
 */
const ModalContainer = ({
  children,
  showModal,
  setShowExternal
}: ChildrenProps & {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
} & ModalContainerChildren): JSX.Element => {
  const [show, setShow] = useState(false);

  const contextValue = useMemo(
    () => ({
      show: showModal ?? show,
      setShow: setShowExternal ?? setShow
    }),
    [showModal, show]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContainer.Title = Title;
ModalContainer.Body = Body;
ModalContainer.Header = Header;
ModalContainer.Footer = Footer;
ModalContainer.Toggle = Toggle;
ModalContainer.Display = ModalDisplay;

export default ModalContainer;
